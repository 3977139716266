import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  cancelRequest,
  duplicate,
  loadRequest,
} from '../../utilities/requests';
import OrderDate from './OrderDate';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';

export default function EditDeliveryModal({ show, onHide, requestId }) {
  const [request, setRequest] = useState(null);
  useEffect(() => {
    if (requestId) {
      loadRequest(requestId).then((request) => {
        setRequest(request);
      });
    }
  }, [requestId]);

  const [newDate, setNewDate] = useState(null);

  const handleSubmit = () => {
    console.log(newDate, request.Job.pickup_at);
    if (newDate) {
      cancelRequest(
        requestId,
        request.ClientAccountID,
        'Probleme technique',
        ''
      ).then(() => {
        duplicate(requestId, {
          pickup_at: newDate,
        }).then(() => {
          console.log('duplicated');
        });
      });
    } else {
      alert('selectionner date de rendez vous');
    }
  };

  if (!request) return <></>;

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Modifier la date de rendez vous</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='alert alert-info'>
          Date de rendez vous actuelle:{' '}
          {dayjs(request.Job.pickup_at).format('DD/MM/YYYY HH:mm')}
        </div>

        <div className='alert alert-warning'>
          En choisissant une nouvelle date, vous allez annuler la commande
          actuelle et créer une nouvelle commande avec la date de rendez vous
          renseignée.
        </div>
        <b>Choisir une nouvelle date de rendez vous:</b>
        <OrderDate
          client={request.ClientAccountID}
          setter={(data) => {
            setNewDate(data);
          }}
          value={dayjs(request.Job.pickup_at).format('DD/MM/YYYY HH:mm')}
          address={''}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='submit'
          variant='success'
          onClick={() =>
            window.confirm(
              'Êtes-vous sûr de vouloir modifier la date de rendez vous ?'
            ) && handleSubmit()
          }
        >
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
