import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faBolt,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getSlot } from '../../utilities/requests';
import { element } from 'prop-types';

dayjs.extend(customParseFormat);

export const REGIONS = [
  'aix_en_provence',
  'amiens',
  'angers',
  'angouleme',
  'annecy',
  'antibes',
  'aubagne',
  'avignon',
  'beauvais',
  'besancon',
  'beziers',
  'blois',
  'bordeaux',
  'boulogne_sur_mer',
  'brest',
  'brive',
  'caen',
  'calais',
  'cannes',
  'cazeres',
  'charleville_mezieres',
  'chembourg',
  'cholet',
  'clermont_ferrand',
  'colmar',
  'dijon',
  'dinard',
  'douai',
  'dunkerque',
  'evreux',
  'foix',
  'grenoble',
  'guerande',
  'la_rochelle',
  'le_havre',
  'le_mans',
  'lille',
  'limoges',
  'lyon',
  'marseille',
  'merignac',
  'merlimont',
  'metz',
  'montpellier',
  'mulhouse',
  'nancy',
  'nantes',
  'nice',
  'nimes',
  'niort',
  'orleans',
  'pamiers',
  'paris',
  'perigueux',
  'perpignan',
  'poitiers',
  'quimper',
  'reims',
  'rennes',
  'roubaix',
  'rouen',
  'saint_brieuc',
  'saint_etienne',
  'saint_nazaire',
  'saint_quentin',
  'strasbourg',
  'thionville',
  'toulon',
  'toulouse',
  'tours',
  'valence',
  'valenciennes',
  'vannes',
  'basildon',
  'birmingham',
  'blackpool',
  'barcelona',
  'girona',
  'madrid',
  'valles',
];

const dates = new Array(30).fill(dayjs()).map((m, i) => dayjs(m).add(i, 'day'));

const getSlots = (startTime, endTime, interval) => {
  const next = dayjs(startTime).add(interval, 'minute');
  const shouldInclude = startTime.isAfter(dayjs());
  const base = shouldInclude ? [startTime] : [];
  if (next.isAfter(endTime)) return [...base];
  return [...base, ...getSlots(next, endTime, interval)];
};

const getAddressZone = (address) => {
  try {
    const addressSubstrings = address.split(', ');
    const dropOffZone = addressSubstrings[addressSubstrings.length - 2]
      .replace(/\s/g, '_')
      .toLowerCase();

    return REGIONS.includes(dropOffZone) ? dropOffZone : 'paris';
  } catch {
    return 'paris';
  }
};

const getSlotsOfDay = async (client, date, address) => {
  const region = getAddressZone(address);
  try {
    const response = await getSlot({
      client: client,
      region: region,
      date: dayjs(date).format('YYYY-MM-DD'),
    });

    return response.slots;
  } catch (error) {
    if (error.error.message === 'Invalid date.') {
      throw new Error('Invalid date.');
    }
  }
  const startTime = dayjs(date).hour(7).minute(0);
  const endTime = dayjs(date).hour(22).minute(30);
  const times = getSlots(startTime, endTime, 15);

  return times;
};

export const OrderDate = (props) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dates[0]);
  const [slots, setSlots] = useState([]);
  const [address, setAddress] = useState('');
  const [isLaterChecked, setIsLaterChecked] = useState(true);
  const setQuery = (value) => {
    const settedValue = value;
    props.setter(settedValue);
  };

  const updateDate = (value) => {
    setDate(value);
    setQuery(dayjs(value).format());
  };

  const updateSlot = (e) => {
    setQuery(e.target.value);
  };

  const setLater = () => {
    setQuery(dayjs(slots[0]).format());
    setIsLaterChecked(true);
  };

  const getFormattedSlot = (dateString) => {
    const timeMatch = dateString.match(/T(\d{2}:\d{2}):\d{2}/);
    return timeMatch ? timeMatch[1] : null;
  };

  useEffect(() => {
    setAddress(props.address);
  }, [props.address]);

  const [disableSlot, setDisableSlot] = useState(false);

  useEffect(() => {
    getSlotsOfDay(props.client, date, address)
      .then((slots) => {
        setDisableSlot(false);
        setSlots([...slots]);
        setQuery(
          slots[0]?.start_time !== undefined
            ? slots[0].start_time.replace('00.000+0200', '00+01:00')
            : dayjs(slots[0]).format().replace('00.000+0200', '00+01:00')
        );
      })
      .catch((err) => {
        console.error('error', err);
        setDisableSlot(true);
      });
  }, [date, address]);

  useEffect(() => {
    setDisableSlot(false);
    updateDate(dates[0]);
  }, []);

  return (
    <Form>
      {isLaterChecked && (
        <Row>
          <Col xs={6}>
            {t('date')}
            <br />
            <Form.Control
              as='select'
              onChange={(event) =>
                updateDate(dayjs(event.target.value, 'DD MMMM YY'))
              }
            >
              {dates.map((dateOption, index) => (
                <option key={index} value={dateOption.format('DD MMMM YY')}>
                  {dateOption.format('DD MMMM')}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col xs={6}>
            {t('timeSlot')}
            <br />
            <Form.Control
              disabled={disableSlot}
              as='select'
              onChange={updateSlot}
              // value={
              //   slots[0]?.start_time !== undefined
              //     ? slots[0].start_time.replace('00.000+0200', '00+01:00')
              //     : dayjs(slots[0])
              //         .format()
              //         .replace('00.000+0200', '00+01:00')
              // }
            >
              {slots?.map((element, index) => (
                <option
                  key={index}
                  value={
                    element.start_time !== undefined
                      ? element.start_time.replace('00.000+0200', '00+01:00')
                      : dayjs(element)
                          .format()
                          .replace('00.000+0200', '00+01:00')
                  }
                >
                  {element.start_time !== undefined
                    ? `${getFormattedSlot(
                        element.start_time
                      )} - ${getFormattedSlot(element.end_time)}`
                    : `${element.format('HH:mm')} - ${dayjs(element)
                        .add(120, 'minutes')
                        .format('HH:mm')}`}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default OrderDate;
